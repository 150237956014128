import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  set(key: string, value: any): any {
    return Promise.resolve().then(() => {
      sessionStorage.setItem(key, JSON.stringify(value));
    });
  }

  get(key: string): Promise<any> {
    return Promise.resolve().then(() => {
      return JSON.parse(sessionStorage.getItem(key));
    });
  }

  remove(key: string): Promise<any> {
    return Promise.resolve().then(() => {
      sessionStorage.removeItem(key);
    });
  }
}
