import { Constants } from '@helpers/constants';
import { Location } from '@angular/common';

export class Page {
  readonly pages = Constants.Pages;

  constructor(
    public location: Location
  ) { }

  goBack(): void {
    this.location.back();
  }
}
