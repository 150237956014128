import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StoreInterface } from '@interfaces/store.interface';
import { Store } from '@ngrx/store';
import { ApiService } from '@services/api.service';
import * as ViewinGRequestsActions from '@actions/viewing-requests.actions';
import { exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class ViewingRequestsEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewinGRequestsActions.viewingRequestsGet),
      withLatestFrom(this.store.select('viewingRequests')),
      map(([action, viewingRequests]) => {
        return {
          page:  viewingRequests.currentPage,
          itemsPerPage: viewingRequests.itemsPerPage
        };
      }),
      exhaustMap(({page, itemsPerPage}) => {
        return this.api.getViewingRequests(page, itemsPerPage).pipe(
          map((viewingRequests) => {
            return ViewinGRequestsActions.viewingRequestsSet({
              payload: {
                viewingRequests: viewingRequests.items,
                lastPage: Math.ceil(viewingRequests.totalItems / itemsPerPage),
                totalItems: viewingRequests.totalItems
              }
            });
          })
        );
      })
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewinGRequestsActions.viewingRequestsUpdate),
      exhaustMap(() => {
        return of(ViewinGRequestsActions.viewingRequestsGet());
      })
    )
  );

  constructor(
    private store: Store<StoreInterface>,
    private actions$: Actions,
    private api: ApiService
  ) {
  }
}
