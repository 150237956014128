import { createAction, props } from '@ngrx/store';
import { OffersReducerInterface } from '@interfaces/offers-reducer.interface';

const OFFERS_GET = 'OFFERS_GET';
const OFFERS_SET = 'OFFERS_SET';
const OFFERS_UPDATE = 'OFFERS_UPDATE';
// const OFFERS_RESET_SEARCH_FILTERS = 'OFFERS_RESET_SEARCH_FILTERS';

export const offersGet = createAction(
  OFFERS_GET
);

export const offersSet = createAction(
  OFFERS_SET,
  props<{ payload: Partial<OffersReducerInterface>}>()
);

export const offersUpdate = createAction(
  OFFERS_UPDATE,
  props<{payload: Partial<OffersReducerInterface>}>()
);

// export const offersResetSearchFilters = createAction(
//   OFFERS_RESET_SEARCH_FILTERS
// );
