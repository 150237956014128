export enum ToastType {
  Success = 'success',
  Danger = 'danger'
}

export interface Toast {
  id: string;
  type: ToastType;
  text: string;
}
