import { EventEmitter, Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {Constants} from '@helpers/constants';
import {HttpClient} from '@angular/common/http';
import {UserCredentialsInterface} from '@interfaces/user-credentials.interface';
import {Profile} from '@interfaces/profile.interface';
import { Token } from '@interfaces/token.interface';
import { StorageService } from '@services/storage.service';
import { Property } from '@interfaces/property.interface';
import { PropertySearchFilters } from '@interfaces/property-search-filters.interface';
import { ProfileSearchFilters } from '@interfaces/profile-search-filters.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthToken } from '@interfaces/authtoken.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  accessToken: string;

  onLogOut$: EventEmitter<void> = new EventEmitter<void>();
  onLogin$: EventEmitter<AuthToken> = new EventEmitter<AuthToken>();

  constructor(public http: HttpClient,
              private sanitizer: DomSanitizer,
              private storage: StorageService) {
    this.initializeToken();
  }

  refreshAccessToken(): Observable<any> {
    return from(this.storage.get(Constants.Storage.AuthenticationTokens)).pipe(
      switchMap(tokens => {
        const refreshToken = tokens ? tokens.refresh_token : null;
        return this.http.post(Constants.Api.Refresh, {refresh_token: refreshToken});
      }),
      switchMap((response: AuthToken) => this.setAuthToken(response)),
    );
  }

  private setAuthToken(token: AuthToken, persistToStorage = true) {
    this.accessToken = token.token;
    this.onLogin$.emit(token);

    if (persistToStorage) {
      return from(this.storage.set(Constants.Storage.AuthenticationTokens, token));
    } else {
      return of(true);
    }
  }

  private clearAuthToken() {
    this.accessToken = null;

    return this.storage.remove(Constants.Storage.AuthenticationTokens);
  }

  signOut() {
    this.clearAuthToken();
    this.onLogOut$.emit();
  }

  registerUser(userCredentials: UserCredentialsInterface): Observable<any> {
    return this.http.post(Constants.Api.Register, userCredentials)
      .pipe(
        switchMap((response: AuthToken) => this.setAuthToken(response)),
      );
  }

  signUserIn(userCredentials: UserCredentialsInterface): Observable<any> {
    return this.http.post(Constants.Api.SignIn, userCredentials)
      .pipe(
        switchMap((response: AuthToken) => this.setAuthToken(response)),
      );
  }

  createProperty(property: Partial<Property>): Observable<any> {
    return this.http.post(Constants.Api.Properties, property);
  }

  updateProperty(id: string, property: Partial<Property>): Observable<any> {
    return this.http.put(`${Constants.Api.Properties}/${id}`, property);
  }

  getProperties(
    page: number,
    itemsPerPage: number,
    searchFilters: PropertySearchFilters,
    internalOnly: boolean
  ): Observable<any> {
    let queryParams = {
      ...this.getOverviewPageParams(page, itemsPerPage, null, searchFilters),
      'order[offeredSince]': 'desc'
    };

    if (internalOnly) {
      // @ts-ignore
      queryParams = {...queryParams, internal: '1'};
    }

    return this.http.get(Constants.Api.Properties, {
      params: queryParams
    });
  }

  getUsers(
    page: number,
    itemsPerPage: number,
    searchFilters: ProfileSearchFilters,
  ): Observable<any> {
    return this.http.get(Constants.Api.Users, {
      params: {
        ...this.getOverviewPageParams(page, itemsPerPage, null, searchFilters),
        'order[firstName]': 'asc'
      }
    });
  }

  getProfilesForDashboard(
    page: number,
    itemsPerPage: number,
    searchFilters: ProfileSearchFilters,
  ): Observable<any> {
    return this.http.get(Constants.Api.Users, {
      params: {
        ...this.getOverviewPageParams(page, itemsPerPage, null, searchFilters),
        'order[lastTokenRefresh]': 'desc'
      }
    });
  }

  getAllRecentProfiles(afterDate: string) {
    return this.http.get(Constants.Api.Users, {
      params: {
        'lastTokenRefresh[after]': afterDate,
        pagination: '0'
      }
    });
  }

  getNewUsers(
    page: number,
    itemsPerPage: number,
    searchFilters: ProfileSearchFilters,
  ): Observable<any> {
    return this.http.get(Constants.Api.Users, {
      params: {
        ...this.getOverviewPageParams(page, itemsPerPage, null, searchFilters),
        'order[createdAt]': 'desc'
      }
    });
  }

   getUser(id: string): Observable<any> {
    return this.http.get(Constants.Api.User(id));
  }

  updateProfile(profile: Profile, updateProfile: Partial<Profile>): Observable<any> {
    return this.http.put(Constants.Api.User(profile.id), updateProfile);
  }

  deleteProfile(profile: Profile): Observable<any> {
    return this.http.delete(Constants.Api.DeleteUser(profile.id));
  }

  getProperty(id: string): Observable<any> {
    return this.http.get(`${Constants.Api.Properties}/${id}`);
  }

  getPropertyTypes(): Observable<any> {
    return this.http.get(Constants.Api.PropertyTypes);
  }

  getPropertyFlyer(property: Property): Observable<any> {
    return this.http.get(Constants.Api.PropertyFlyer(property.id), {
      responseType: 'blob'
    }).pipe(
      map((blob: Blob) => {
        const a = document.createElement('a');
        a.setAttribute('style', 'display: none');
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `flyer-${property.street}-${property.houseNumber}${property.houseNumberAddition}.pdf`;
        a.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
        window.URL.revokeObjectURL(url);
      })
    );
  }

  getOffers(page: number, itemsPerPage: number, userId: string = null, searchFilters: ProfileSearchFilters = null): Observable<any> {
    return this.http.get(Constants.Api.Offers, {
      params: {
        ...this.getOverviewPageParams(page, itemsPerPage, userId, searchFilters),
        'order[createdAt]': 'desc'
      }
    });
  }

  getViewingRequests(
    page: number,
    itemsPerPage: number,
    userId: string = null,
    searchFilters: ProfileSearchFilters = null): Observable<any> {
    return this.http.get(Constants.Api.ViewingRequest, {
      params: {
        ...this.getOverviewPageParams(page, itemsPerPage, userId, searchFilters),
        'order[createdAt]': 'desc'
      }
    });
  }

  getUserPositiveReactions(page: number,
                           itemsPerPage: number,
                           userId: string): Observable<any> {
    return this.http.get(Constants.Api.Reactions, {
      params: {
        'owner.uuid': userId,
        'order[createdAt]': 'desc',
        positive: '1',
        ...this.getOverviewPageParams(page, itemsPerPage)
      }
    });
  }

  resetPassword(passwordCredentials: {password: string; code: string}): Observable<any> {
    return this.http.post(Constants.Api.ResetPassword.Reset, passwordCredentials);
  }

  requestPasswordReset(userCredentials: UserCredentialsInterface): Observable<any> {
    return this.http.post(Constants.Api.ResetPassword.Request, userCredentials);
  }

  uploadPhoto(property: Property, data): Observable<any> {
    return this.http.post(Constants.Api.UploadPhoto(property.id), {
      content: data
    });
  }

  getPropertyImage(imageUrl: string): Observable<any> {
    return this.http.get(imageUrl, { responseType: 'blob'});
  }

  private initializeToken() {
    this.storage.get(Constants.Storage.AuthenticationTokens)
      .then((tokens: Token) => {
        if (tokens) {
          this.setAuthToken(tokens, false);
        }
      });
  }

  private getOverviewPageParams(page: number, itemsPerPage: number, userId: string = null, searchFilters: object = null) {
    const params = {
      page: page.toString(),
      itemsPerPage: itemsPerPage.toString(),
    };

    if (!!userId) {
      params['owner.uuid'] = userId;
    }

    if (!!searchFilters) {
      Object.keys(searchFilters).forEach((key) => {
        if (!!searchFilters[key]) {
          params[key] = searchFilters[key];
        }
      });
    }

    return params;
  }

  getPropertyReactions(propertyId: string, positive: boolean = true, pinned: boolean = false) {
    return this.http.get(Constants.Api.Reactions, {
      params: {
        ['property.uuid']: propertyId,
        positive: positive ? '1' : '0',
        pinned: pinned ? '1' : '0'
      }
    });
  }

  getMostLikedProperties(): Observable<any> {
    return this.http.get(Constants.Api.MostLikedProperties);
  }

  getAllMostLikedProperties(): Observable<any> {
    return this.http.get(Constants.Api.MostLikedProperties, {
      params: {
        all: '1'
      }
    });
  }

  getRecentContactMoment(): Observable<any> {
    return this.http.get(Constants.Api.RecentContactMoments);
  }

  exportToExcel(): Observable<any> {
    return this.http.get(Constants.Api.UsersExport, {
      responseType: 'text'
    });
  }

  deleteProperty(property: Property): Observable<any> {
    return this.http.delete(Constants.Api.DeleteProperty(property.id));
  }
}
