import { Action, createReducer, on } from '@ngrx/store';
import * as OffersActions from '@actions/offers.actions';
import { OffersReducerInterface } from '@interfaces/offers-reducer.interface';

export const initialProfilesSearchFilters = {
  firstName: null,
  lastName: null,
  ['urgency.urgency']: null
};

const initialState: OffersReducerInterface = {
  offers: [],
  currentPage: 1,
  totalItems: 0,
  itemsPerPage: 30,
  lastPage: 1,
  searchFilters: initialProfilesSearchFilters
};

const reducer = createReducer(
  initialState,
  on(OffersActions.offersGet, (currentState) => currentState),
  on(OffersActions.offersUpdate, (currentState, action) => {
    return {
      ...currentState,
      ...action.payload
    };
  }),
  on(OffersActions.offersSet, (currentState, action) => {
    return {
      ...currentState,
      ...action.payload
    };
  })
);

export function offersReducer(state = initialState, action: Action) {
  return reducer(state, action);
}

