import { Action, createReducer, on } from '@ngrx/store';
import * as PropertiesActions from '@actions/properties.actions';
import { PropertiesReducerInterface } from '@interfaces/properties-reducer.interface';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Property } from '@interfaces/property.interface';

export const initialPropertiesSearchFilters = {
  street: null,
  city: null,
  postalCode: null
};

export const adapter: EntityAdapter<Property> = createEntityAdapter<Property>({
  selectId: (a: Property) => a.id
});

const initialState: PropertiesReducerInterface = adapter.getInitialState({
  currentPage: 1,
  totalItems: 0,
  lastPage: 0,
  itemsPerPage: 10,
  internalOnly: false,
  searchFilters: initialPropertiesSearchFilters
});


const reducer = createReducer(
  initialState,
  on(PropertiesActions.propertiesSet, (currentState, action) => {
    return adapter.addAll(action.payload.properties, {
      ...currentState,
      totalItems: action.payload.totalItems,
      lastPage: action.payload.lastPage
    });
  }),
  on(PropertiesActions.propertiesSetById, (currentState, action) => {
    return adapter.upsertOne(action.payload, currentState);
  }),
  on(PropertiesActions.propertiesUpdatePhotos, (currentState, action) => {
    return adapter.upsertOne(action.payload as Property, currentState);
  }),
  on(PropertiesActions.propertiesUpdatePhotosRejected, (currentState) => {
    // todo do something when photos are rejected
    return currentState;
  }),
  on(PropertiesActions.propertiesSetSearchFilters, (currentState, { payload }) => {
    return {
      ...currentState,
      currentPage: payload.currentPage,
      searchFilters: payload.searchFilters
    };
  }),
  on(PropertiesActions.propertiesUpdate, (currentState, action) => {
    return {
      ...currentState,
      ...action.payload
    };
  }),
  on(PropertiesActions.propertiesSetInternalOnly, (currentState, action) => {
    return {
      ...currentState,
      ...action.payload
    };
  }),
  on(PropertiesActions.propertiesResetSearchFilters, (currentState) => {
    return {
      ...currentState,
      currentPage: 1,
      searchFilters: initialPropertiesSearchFilters
    };
  }),
  on(PropertiesActions.propertyDelete, (currentState, action) => {
    return adapter.removeOne(action.property.id, currentState);
  })
);

export function propertiesReducer(state = initialState, action: Action) {
  return reducer(state, action);
}

