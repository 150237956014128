import { NgModule } from '@angular/core';
import { IconComponent } from '@components/icon/icon.component';
import { CommonModule } from '@angular/common';
import { ProfilePropertiesComponent } from './profile-properties/profile-properties.component';
import { RouterModule } from '@angular/router';
import { SanitizeBackgroundImagePipe } from '@pipes/sanitize-background-image.pipe';
import { ConfirmComponent } from '@components/confirm/confirm.component';
import { ToastComponent } from '@components/toast/toast.component';

@NgModule({
  declarations: [
    IconComponent,
    ToastComponent,
    ConfirmComponent,
    SanitizeBackgroundImagePipe,
    ProfilePropertiesComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports: [
    IconComponent,
    CommonModule,
    ToastComponent,
    ConfirmComponent,
    ProfilePropertiesComponent,
    SanitizeBackgroundImagePipe,
  ]
})
export class SharedModule { }
