import { Component, OnInit } from '@angular/core';
import { ConfirmService } from '@services/confirm.service';
import { ConfirmInterface } from '@interfaces/confirm.interface';

@Component({
  selector: 'app-confirm',
  styleUrls: ['./confirm.component.scss'],
  templateUrl: './confirm.component.html'
})
export class ConfirmComponent implements OnInit {
  isVisible = false;
  confirm: ConfirmInterface;

  constructor(public confirmService: ConfirmService) {
  }

  ngOnInit(): void {
    this.confirmService.activeConfirmation.subscribe((confirm: ConfirmInterface|null) => {
      if (!confirm) {
        this.isVisible = false;
        return;
      }

      this.confirm = confirm;
      this.isVisible = true;
    });
  }
}
