import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StoreInterface } from '@interfaces/store.interface';
import { Store } from '@ngrx/store';
import { ApiService } from '@services/api.service';
import * as ProfilesActions from '@actions/profiles.actions';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastService } from '@services/toast.service';
import { ToastType } from '@interfaces/toast.interface';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ProfilesEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfilesActions.profilesGet, ProfilesActions.profilesUpdate),
      withLatestFrom(this.store.select('profiles')),
      map(([action, profiles]) => {
        const tokenRefresh = !!profiles.searchFilters.lastTokenRefreshType;
        let searchFilters = profiles.searchFilters;

        if (tokenRefresh) {
          searchFilters = {
            ...searchFilters,
            [`lastTokenRefresh[${profiles.searchFilters.lastTokenRefreshType}]`]:
              this.getTokenRefresh(profiles.searchFilters.lastTokenRefreshDays)
          };
        }

        return {
          page:  profiles.currentPage,
          itemsPerPage: profiles.itemsPerPage,
          searchFilters
        };
      }),
      switchMap(({itemsPerPage, page, searchFilters}) => {
        return this.api.getUsers(page, itemsPerPage, searchFilters).pipe(
          map((profiles) => {
            return ProfilesActions.profilesSet({
              payload: {
                profiles: profiles.items,
                lastPage: Math.ceil(profiles.totalItems / itemsPerPage),
                totalItems: profiles.totalItems
              }
            });
          })
        );
      })
    )
  );

  delete$ = createEffect(() =>
     this.actions$.pipe(
       ofType(ProfilesActions.profilesDelete),
       switchMap(({profile}) => {
         return this.api.deleteProfile(profile).pipe(
          map(() => {
            this.toastService.addToast(ToastType.Success, this.translate.instant('PROFILE.DELETE_SUCCESS'));
            return ProfilesActions.profilesGet();
          }),
          catchError((e) => {
            console.error(e.message);
            this.toastService.addToast(ToastType.Danger, this.translate.instant('PROFILE.DELETE_FAILURE'));
            return of(ProfilesActions.profilesGet());
          })
         );
       })
     )
  );

  saveNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfilesActions.profileSaveNote),
      switchMap(({profile, note, onError, onSuccess}) => {
        return this.api.updateProfile(profile, {note}).pipe(
          map(() => {
            if (onSuccess) {
              onSuccess();
            }
            return ProfilesActions.profileSaveNoteCompleted();
          }),
          catchError((e) => {
            if (onError) {
              onError();
            }
            console.error(e);
            return of(ProfilesActions.profileSaveNoteFailed());
          })
        );
      })
    )
  );

  getTokenRefresh(days: number): string {
    return moment().subtract(days, 'days').hours(0).minutes(0).seconds(0).toISOString();
  }

  constructor(
    private store: Store<StoreInterface>,
    private actions$: Actions,
    private api: ApiService,
    private toastService: ToastService,
    private translate: TranslateService
  ) {
  }
}
