import { Action, createReducer, on } from '@ngrx/store';
import { PropertyTypeReducerInterface } from '@interfaces/property-type-reducer.interface';
import { PropertyTypeActions } from '@actions/property-type.actions';

const initialState: PropertyTypeReducerInterface = {
  types: {}
};

const reducer = createReducer(
  initialState,
  on(PropertyTypeActions.getPropertyTypesFulfilled, (currentState, {payload}) => {
    return {
      ...currentState,
      types: {
        ...currentState.types,
        ...payload
      }
    };
  })
);

export function propertyTypeReducer(state = initialState, action: Action) {
  return reducer(state, action);
}
