import { createAction, props } from '@ngrx/store';
import { PropertiesReducerInterface } from '@interfaces/properties-reducer.interface';
import { Property } from '@interfaces/property.interface';
import { PropertySearchFilters } from '@interfaces/property-search-filters.interface';

const PROPERTIES_GET = 'PROPERTIES_GET';
const PROPERTIES_GET_BY_ID = 'PROPERTIES_GET_BY_ID';
const PROPERTIES_SET = 'PROPERTIES_SET';
const PROPERTIES_SET_BY_ID = 'PROPERTIES_SET_BY_ID';
const PROPERTIES_UPDATE = 'PROPERTIES_UPDATE';
const PROPERTIES_SET_INTERNAL_ONLY = 'PROPERTIES_SET_INTERNAL_ONLY';
const PROPERTIES_SET_SEARCH_FILTERS = 'PROPERTIES_SET_SEARCH_FILTERS';
const PROPERTIES_UPDATE_PHOTOS_PENDING = 'PROPERTIES_UPDATE_PHOTOS_PENDING';
const PROPERTIES_UPDATE_PHOTOS_FULFILLED = 'PROPERTIES_UPDATE_PHOTOS_FULFILLED';
const PROPERTIES_UPDATE_PHOTOS_REJECTED = 'PROPERTIES_UPDATE_PHOTOS_REJECTED';
const PROPERTIES_RESET_SEARCH_FILTERS = 'PROPERTIES_RESET_SEARCH_FILTERS';
const PROPERTY_DELETE = 'PROPERTY_DELETE';

export const propertiesGet = createAction(
  PROPERTIES_GET
);

export const propertiesGetById = createAction(
  PROPERTIES_GET_BY_ID,
  props<{ payload: string }>()
);

export const propertiesSet = createAction(
  PROPERTIES_SET,
  props<{ payload: {
    properties: Property[];
    lastPage: number;
    totalItems: number;
  }}>()
);

export const propertiesSetInternalOnly = createAction(
  PROPERTIES_SET_INTERNAL_ONLY,
  props<{ payload: {
    internalOnly: boolean
  }}>()
);

export const propertiesUpdatePhotos = createAction(
  PROPERTIES_UPDATE_PHOTOS_PENDING,
  props<{ payload: Partial<Property>}>()
);

export const propertiesUpdatePhotosFulFilled = createAction(
  PROPERTIES_UPDATE_PHOTOS_FULFILLED
);

export const propertiesUpdatePhotosRejected = createAction(
  PROPERTIES_UPDATE_PHOTOS_REJECTED
);

export const propertiesSetById = createAction(
  PROPERTIES_SET_BY_ID,
  props<{ payload: Property}>()
);

export const propertiesUpdate = createAction(
  PROPERTIES_UPDATE,
  props<{payload: Partial<PropertiesReducerInterface>}>()
);

export const propertiesSetSearchFilters = createAction(
  PROPERTIES_SET_SEARCH_FILTERS,
  props<{ payload: {
    currentPage: number;
    searchFilters: PropertySearchFilters
  }}>()
);

export const propertiesResetSearchFilters = createAction(
  PROPERTIES_RESET_SEARCH_FILTERS
);

export const propertyDelete = createAction(
  PROPERTY_DELETE,
  props<{property: Property}>()
);
