import { Component, Input, OnInit } from '@angular/core';
import { ToastService } from '@services/toast.service';
import { Toast } from '@interfaces/toast.interface';

@Component({
  selector: 'app-toast',
  styleUrls: ['./toast.component.scss'],
  templateUrl: './toast.component.html'
})
export class ToastComponent implements OnInit {
  isActive = true;
  @Input() toast: Toast;

  constructor(private toastService: ToastService) {
  }


  ngOnInit() {
    setTimeout(() => {
      this.isActive = false;
      setTimeout(() => {
        this.toastService.removeToast(this.toast.id);
      }, 250);
    }, 5000);
  }
}
