import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfirmInterface } from '@interfaces/confirm.interface';

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {
  activeConfirmation = new BehaviorSubject<ConfirmInterface>(null);

  alert(confirm: ConfirmInterface) {
    this.activeConfirmation.next(confirm);
  }

  confirm() {
    this.activeConfirmation.getValue().confirm();
    this.activeConfirmation.next(null);
  }

  cancel() {
    if (this.activeConfirmation.getValue().hasOwnProperty('cancel')) {
      this.activeConfirmation.getValue().cancel();
    }
    this.activeConfirmation.next(null);
  }
}
