import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {StoreInterface} from '@interfaces/store.interface';
import {Store} from '@ngrx/store';
import {ApiService} from '@services/api.service';
import * as PropertiesActions from '@actions/properties.actions';
import {catchError, exhaustMap, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {ToastType} from '@interfaces/toast.interface';
import {ToastService} from '@services/toast.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class PropertiesEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PropertiesActions.propertiesGet, PropertiesActions.propertiesSetSearchFilters, PropertiesActions.propertiesSetInternalOnly),
      withLatestFrom(this.store.select('properties')),
      map(([action, properties]) => {
        return {
          page:  properties.currentPage,
          itemsPerPage: properties.itemsPerPage,
          searchFilters: properties.searchFilters,
          internalOnly: properties.internalOnly
        };
      }),
      switchMap(({itemsPerPage, page, searchFilters, internalOnly}) => {
        return this.api.getProperties(page, itemsPerPage, searchFilters, internalOnly).pipe(
          map((properties) => {
            return PropertiesActions.propertiesSet({
              payload: {
                properties: properties.items,
                lastPage: Math.ceil(properties.totalItems / itemsPerPage),
                totalItems: properties.totalItems
              }
            });
          }),
        );
      })
    )
  );

  getById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PropertiesActions.propertiesGetById),
      exhaustMap(action => {
        return this.api.getProperty(action.payload).pipe(
          map((property) => {
            return PropertiesActions.propertiesSetById({
              payload: property
            });
          })
        );
      })
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PropertiesActions.propertiesUpdate),
      exhaustMap(() => {
        return of(PropertiesActions.propertiesGet());
      })
    )
  );

  updatePhotos = createEffect(() =>
    this.actions$.pipe(
      ofType(PropertiesActions.propertiesUpdatePhotos),
      exhaustMap((action) => {
        return this.api.updateProperty(action.payload.id, action.payload).pipe(
          map((property) => {
            return PropertiesActions.propertiesUpdatePhotosFulFilled();
          }),
          catchError(() => {
            return of(PropertiesActions.propertiesUpdatePhotosRejected());
          })
        );
      })
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PropertiesActions.propertyDelete),
      switchMap(({property}) => {
        return this.api.deleteProperty(property).pipe(
          map(() => {
            this.toastService.addToast(ToastType.Success, this.translate.instant('PROPERTY.DELETE_SUCCESS'));
            return PropertiesActions.propertiesGet();
          }),
          catchError((e) => {
            console.error(e.message);
            this.toastService.addToast(ToastType.Danger, this.translate.instant('PROPERTY.DELETE_FAILURE'));
            return of(PropertiesActions.propertiesGet());
          })
        );
      })
    )
  );

  constructor(
    private store: Store<StoreInterface>,
    private actions$: Actions,
    private api: ApiService,
    private toastService: ToastService,
    private translate: TranslateService
  ) {
  }
}
