import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ApiHttpInterceptor } from '@helpers/http.interceptor';
import { SharedModule } from '@components/shared.module';
import localeNl from '@angular/common/locales/nl';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from '@reducers/index';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { PropertiesEffects } from '@effects/properties.effects';
import { ProfilesEffects } from '@effects/profiles.effects';
import { OffersEffects } from '@effects/offers.effects';
import { ViewingRequestsEffects } from '@effects/viewing-requests.effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { HydraInterceptor } from '@helpers/hydra.interceptor';
import { PropertyTypeEffects } from '@effects/property-type.effects';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeNl, 'nl');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    StoreModule.forRoot(
      reducers, {
        metaReducers
      }
    ),
    EffectsModule.forRoot([
      PropertiesEffects,
      ProfilesEffects,
      OffersEffects,
      PropertyTypeEffects,
      ViewingRequestsEffects
    ]),
    StoreDevtoolsModule.instrument({
      logOnly: environment.production
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    StoreRouterConnectingModule.forRoot(),
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'nl'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HydraInterceptor,
      multi: true
    },
    {
      provide: 'CURRENCY_CODE',
      useValue: '€'
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
