import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export class HydraInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((res: HttpResponse<any>) => {
        if (!res.body) {
          return res;
        }

        const isHydraDetailObject = !!res.body['@id'] && !res.body['hydra:member'];

        if (!res.body['hydra:member'] && !isHydraDetailObject) {
          return res;
        }

        if (isHydraDetailObject) {
          return new HttpResponse({
            body: this.reduceObject(res.body)
          });
        }

        const hydraCollection = res.body['hydra:member'];

        const newResponse = hydraCollection.reduce((total, current) => {
          if (!current['@id']) {
            return total;
          }

          return [
            ...total,
            {
              ...this.reduceObject(current)
            }
          ];
        }, []);

        return new HttpResponse({body: {
          items: newResponse,
          totalItems: res.body['hydra:totalItems']
        }});
      })
    );
  }

  private reduceObject(item: any) {
    const convertedObject = {
      ...item
    };

    if (item['@id']) {
      const splittedId = item['@id'].split('/');
      convertedObject.id = splittedId[splittedId.length - 1];
    }

    return Object.keys(convertedObject).reduce((total, current) => {
      if (typeof convertedObject[current] !== 'object' || !convertedObject[current] || Array.isArray(convertedObject[current])) {
        return {
          ...total,
          [current]: convertedObject[current]
        };
      }

      return {
        ...total,
        [current]: this.reduceObject(convertedObject[current])
      };
    }, {});
  }
}
