import { Action, createReducer, on } from '@ngrx/store';
import * as ViewingRequestsActions from '@actions/viewing-requests.actions';
import { ViewingRequestsReducerInterface } from '@interfaces/viewing-requests-reducer.interface';

export const initialProfilesSearchFilters = {
  firstName: null,
  lastName: null,
  ['urgency.urgency']: null
};

const initialState: ViewingRequestsReducerInterface = {
  viewingRequests: [],
  currentPage: 1,
  totalItems: 0,
  itemsPerPage: 30,
  lastPage: 1,
  searchFilters: initialProfilesSearchFilters
};

const reducer = createReducer(
  initialState,
  on(ViewingRequestsActions.viewingRequestsGet, (currentState) => currentState),
  on(ViewingRequestsActions.viewingRequestsUpdate, (currentState, action) => {
    return {
      ...currentState,
      ...action.payload
    };
  }),
  on(ViewingRequestsActions.viewingRequestsSet, (currentState, action) => {
    return {
      ...currentState,
      ...action.payload
    };
  })
);

export function viewingRequestsReducer(state = initialState, action: Action) {
  return reducer(state, action);
}

