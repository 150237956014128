import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {ApiService} from '@services/api.service';
import {environment} from '../../environments/environment';
import {catchError, switchMap} from 'rxjs/operators';
import {Constants} from '@helpers/constants';

export class ApiHttpInterceptor implements HttpInterceptor {

  constructor(public apiService: ApiService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const setAccessToken = () => {
      if (this.apiService.accessToken) {
        // Set the Authentication header for every request
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.apiService.accessToken}`,
            ['X-Purpose']: 'admin',
          }
        });
      }
    };

    setAccessToken();
    // Add apiEndpoint to the request url
    // Translate files also go through this interceptor
    if (!request.url.includes('assets')) {
      request = request.clone({
        url: `${environment.apiEndpoint}${request.url}`
      });
    }

    return next.handle(request)
      .pipe(catchError(err => {
        if (err.status === 401) {
          if (request.url === `${environment.apiEndpoint}${Constants.Api.Refresh}`) {
            // If you get a 401 while refreshing, your refresh_token isn't valid anymore
            this.apiService.signOut();
          } else if (!request.url.includes('jwt')) {
            // Refresh tokens if request url does not include anything of `jwt`
            return this.apiService.refreshAccessToken()
              .pipe(
                switchMap(() => {
                  // Set the header with the new accessToken
                  setAccessToken();
                  // Return original request
                  return next.handle(request);
                })
              );
          }
        }

        return throwError(err);
      }));
  }

}
