import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {

  @Input()
  name: string;

  @Input()
  alt?: string;

  @Input()
  color?: string;

  @Input()
  class?: string;

  constructor(private sanitize: DomSanitizer) {
  }

  styling: SafeStyle;

  ngOnInit(): void {
    this.styling = this.sanitize.bypassSecurityTrustStyle('-webkit-mask-image: url(' +
      './assets/icon/' + this.name + '.svg' + ');' +
      'mask-image: url(' +
      './assets/icon/' + this.name + '.svg' + ');');
  }

}
