import { createAction, props } from '@ngrx/store';
import { AnyKey } from '@interfaces/anykey.interface';

const PROPERTY_TYPES_GET = 'PROPERTY_TYPES_GET';
const PROPERTY_TYPES_GET_FULFILLED = 'PROPERTY_TYPES_GET_FULFILLED';

const getPropertyTypes = createAction(
  PROPERTY_TYPES_GET
);

const getPropertyTypesFulfilled = createAction(
  PROPERTY_TYPES_GET_FULFILLED,
  props<{ payload: AnyKey}>()
);

export const PropertyTypeActions = {
  getPropertyTypes,
  getPropertyTypesFulfilled
};
