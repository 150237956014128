import { ProfilesReducerInterface } from '@interfaces/profiles-reducer.interface';
import { Action, createReducer, on } from '@ngrx/store';
import * as ProfilesActions from '@actions/profiles.actions';

export const initialProfilesSearchFilters = {
  firstName: null,
  lastName: null,
  ['urgency.urgency']: null,
  'lastTokenRefresh[after]': null
};

const initialState: ProfilesReducerInterface = {
  profiles: [],
  currentPage: 1,
  totalItems: 0,
  itemsPerPage: 30,
  lastPage: 1,
  searchFilters: initialProfilesSearchFilters
};

const reducer = createReducer(
  initialState,
  on(ProfilesActions.profilesGet, (currentState) => currentState),
  on(ProfilesActions.profilesResetSearchFilters, (currentState) => {
    return {
      ...currentState,
      currentPage: 1,
      searchFilters: initialProfilesSearchFilters
    };
  }),
  on(ProfilesActions.profilesUpdate, (currentState, action) => {
    return {
      ...currentState,
      ...action.payload,
      searchFilters: {
        ...currentState.searchFilters,
        ...action.payload.searchFilters
      }
    };
  }),
  on(ProfilesActions.profilesDelete, (currentState, action) => {
    return {
      ...currentState,
      profiles: currentState.profiles.filter(profile => profile.id !== action.profile.id)
    };
  }),
  on(ProfilesActions.profileSaveNote, (currentState, action) => {
    const updatedProfiles = currentState.profiles.map((profile) => {
      if (profile.id !== action.profile.id) {
        return profile;
      }

      return {
        ...profile,
        note: action.note
      };
    });

    return {
      ...currentState,
      profiles: updatedProfiles
    };
  }),
  on(ProfilesActions.profilesSet, (currentState, action) => {
    return {
      ...currentState,
      ...action.payload
    };
  })
);

export function profilesReducer(state = initialState, action: Action) {
  return reducer(state, action);
}

