import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [{
  path: 'login',
  loadChildren: () => import('@pages/login/login.module').then(m => m.LoginModule)
}, {
  path: 'reset-password',
  loadChildren: () => import('@pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
}, {
  path: 'wachtwoord-vergeten',
  loadChildren: () => import('@pages/request-password/request-password.module').then(m => m.RequestPasswordModule)
}, {
  path: 'reset-wachtwoord',
  redirectTo: 'reset-password/reset'
}, {
  path: '',
  loadChildren: () => import('@pages/admin/admin.module').then(m => m.AdminModule),
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
