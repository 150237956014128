import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@services/api.service';
import { Constants } from '@helpers/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private apiService: ApiService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.translate.setDefaultLang('nl');
    this.translate.use('nl');

    this.apiService.onLogOut$.subscribe(() => {
      this.router.navigate([Constants.Pages.Login]);
    });

  }
}
