export enum EditPropertyView {
  Description,
  Properties,
  Photos,
  Broker
}

export enum ActiveStateType {
  Before = 'before',
  After = 'after'
}
