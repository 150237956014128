import { createAction, props } from '@ngrx/store';
import { ViewingRequestsReducerInterface } from '@interfaces/viewing-requests-reducer.interface';

const VIEWING_REQUESTS_GET = 'VIEWING_REQUESTS_GET';
const VIEWING_REQUESTS_SET = 'VIEWING_REQUESTS_SET';
const VIEWING_REQUESTS_UPDATE = 'VIEWING_REQUESTS_UPDATE';

export const viewingRequestsGet = createAction(
  VIEWING_REQUESTS_GET
);

export const viewingRequestsSet = createAction(
  VIEWING_REQUESTS_SET,
  props<{ payload: Partial<ViewingRequestsReducerInterface>}>()
);

export const viewingRequestsUpdate = createAction(
  VIEWING_REQUESTS_UPDATE,
  props<{payload: Partial<ViewingRequestsReducerInterface>}>()
);
