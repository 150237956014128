import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeBackgroundImage',
  pure: true
})
export class SanitizeBackgroundImagePipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer
  ) { }

  transform(image: string): any {
    return this.sanitizer.bypassSecurityTrustStyle(`background-image: url("${image}");`);
  }
}
