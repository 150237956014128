import { propertiesReducer } from '@reducers/reducers/properties.reducer';
import { profilesReducer } from '@reducers/reducers/profiles.reducer';
import { offersReducer } from '@reducers/reducers/offers.reducer';
import { viewingRequestsReducer } from '@reducers/reducers/viewing-requests.reducer';
import { routerReducer } from '@ngrx/router-store';
import { propertyTypeReducer } from '@reducers/reducers/property-type.reducer';

export const reducers = {
  properties: propertiesReducer,
  profiles: profilesReducer,
  offers: offersReducer,
  viewingRequests: viewingRequestsReducer,
  router: routerReducer,
  propertyTypes: propertyTypeReducer
};

export const metaReducers = [

];
