import { createAction, props } from '@ngrx/store';
import { ProfilesReducerInterface } from '@interfaces/profiles-reducer.interface';
import { Profile } from '@interfaces/profile.interface';

const PROFILES_GET = 'PROFILES_GET';
const PROFILES_SET = 'PROFILES_SET';
const PROFILES_UPDATE = 'PROFILES_UPDATE';
const PROFILES_DELETE = 'PROFILES_DELETE';
const PROFILE_SAVE_NOTE = 'PROFILE_SAVE_NOTE';
const PROFILE_SAVE_NOTE_COMPLETED = 'PROFILE_SAVE_NOTE_COMPLETED';
const PROFILE_SAVE_NOTE_FAILED = 'PROFILE_SAVE_NOTE_FAILED';
const PROFILES_RESET_SEARCH_FILTERS = 'PROFILES_RESET_SEARCH_FILTERS';

export const profileSaveNoteCompleted = createAction(PROFILE_SAVE_NOTE_COMPLETED);
export const profileSaveNoteFailed = createAction(PROFILE_SAVE_NOTE_FAILED);

export const profilesGet = createAction(
  PROFILES_GET
);

export const profilesDelete = createAction(
  PROFILES_DELETE,
  props<{profile: Profile}>()
);

export const profilesSet = createAction(
  PROFILES_SET,
  props<{ payload: Partial<ProfilesReducerInterface>}>()
);

export const profileSaveNote = createAction(
  PROFILE_SAVE_NOTE,
  props<{
    profile: Profile,
    note: string;
    onSuccess?: () => any;
    onError?: () => any;
  }>()
);

export const profilesUpdate = createAction(
  PROFILES_UPDATE,
  props<{ payload: Partial<ProfilesReducerInterface>}>()
);

export const profilesResetSearchFilters = createAction(
  PROFILES_RESET_SEARCH_FILTERS
);
