import { ActiveStateType } from '@helpers/enums';

export const Constants = {
  Storage: {
    AuthenticationTokens: 'authentication_tokens'
  },
  Form: {
    PasswordLength: 8,
  },
  HouseSettings: {
    LivingSurface: {
      Lower: 0,
      Upper: 400
    },
    ParcelSurface: {
      Lower: 0,
      Upper: 5000
    },
    PriceRange: {
      Lower: 0,
      Upper: 1000000,
      Step: 10000
    },
    AmountOfRooms: {
      Lower: 0,
      Upper: 20,
      Step: 1
    }
  },
  Reasons: {
    Expensive: 'Te duur',
    Small: 'Te klein',
    Large: 'Te groot',
  },
  PropertyForm: {
    StatusOptions: [{
      value: 'Nog niet op funda',
      label: 'Nog niet op funda',
      default: true
    }, {
      value: 'verkocht onder voorbehoud',
      label: 'Verkocht onder voorbehoud'
    }, {
      value: 'onder optie',
      label: 'Onder optie'
    }, {
      value: 'verkocht',
      label: 'Verkocht'
    }, {
      value: 'ingetrokken',
      label: 'Ingetrokken'
    }, {
      value: 'onder bod',
      label: 'Onder bod',
    }, {
      value: 'beschikbaar',
      label: 'Beschikbaar'
    }],
    types: [{
      value: 'Rijtjeshuis'
    }, {
      value: 'Twee-onder-een-kap'
    }, {
      value: 'Hoekwoning'
    }, {
      value: 'Geschakelde woning'
    }, {
      value: 'Vrijstaande woning'
    }]
  },
  Pages: {
    Properties: '/woningen',
    AddProperty: '/woningen/toevoegen',
    Dashboard: '/dashboard',
    PasswordReset: '/login/wachtwoord-reset-password',
    Login: '/login',
    Profiles: '/profielen',
    Offers: '/profielen/bod-uitgebracht',
    ViewingRequests: '/profielen/bezichtigingen',
    RequestPassword: '/wachtwoord-vergeten'
  },
  Api: {
    SignIn: 'jwt/token',
    Refresh: 'jwt/token/refresh',
    ResetPassword: {
      Request: 'users/reset_password/request',
      Reset: 'users/reset_password/reset'
    },
    Offers: 'offers',
    ViewingRequests: 'viewing_requests',
    Register: 'users/register',
    Properties: 'properties',
    PropertyTypes: 'property_types',
    UploadPhoto: (propertyId) => `properties/${propertyId}/upload_photo`,
    PropertySuggest: 'properties-overview/suggest',
    PropertyFlyer: (id) => `properties/${id}/flyer`,
    Property: (id) => id,
    Reactions: 'reactions',
    MyProfile: 'users/me',
    Users: 'users',
    User: (id) => `users/${id}`,
    DeleteUser: (id) => `users/${id}`,
    ViewingRequest: 'viewing_requests',
    UpdateProfile: 'users/update_profile',
    UpdateCredentials: 'users/update_credentials',
    MostLikedProperties: 'properties/most_liked',
    RecentContactMoments: 'properties/contact_moments',
    UsersExport: 'users/export',
    DeleteProperty: (id) => `properties/${id}`
  },
  MaxPinnedItems: 5,
  UrgencyLevels: {
    Direct: 'direct',
    WithinSixMonths: 'within6months',
    WithinOneYear: 'within1year',
    AfterAYear: 'after1year'
  },
  CurrentSituationAnswers: {
    Renting: 'renting',
    Boarding: 'boarding',
    Owner: 'owner'
  },
  OwnerSituationAnswers: {
    None: 'none',
    NotForSale: 'not_for_sale',
    ForSale: 'for_sale',
    Sold: 'sold'
  },
  Contact: {
    Mail: 'info@familiebeekhuizen.nl',
    Phone: ''
  },
  UrgencyLevelsMapping: {
    none: 'Geen',
    direct: 'Per direct',
    within6months: 'Binnen nu en 6 maanden',
    within1year: '6 tot  12 maanden',
    after1year: 'Langer dan een jaar'
  },
  ActiveStateMapping: {
    all: 'Alle',
    today: 'Vandaag',
    yesterday: 'Sinds gisteren',
    lastWeek: 'Sinds vorige week',
    longerThanTwoWeeks: 'Langer dan 2 weken geleden',
  },
  ActiveStateKeyInDays: {
    all: {
      days: null
    },
    today: {
      days: 0,
      type: ActiveStateType.After
    },
    yesterday: {
      days: 1,
      type: ActiveStateType.After
    },
    lastWeek: {
      days: 7,
      type: ActiveStateType.After
    },
    longerThanTwoWeeks: {
      days: 14,
      type: ActiveStateType.Before
    },
  },
  ProfilesSearchItems: [
    {
      key: 'firstName',
      value: '',
      placeholder: 'Voornaam'
    },
    {
      key: 'lastName',
      value: '',
      placeholder: 'Achternaam'
    },
    {
      key: 'urgency.urgency',
      hideFromForm: true,
      value: '',
      placeholder: 'Urgentie'
    }
  ],
  PropertiesSearchItems: [
    {
      key: 'street',
      value: '',
      placeholder: 'Straat'
    },
    {
      key: 'postalCode',
      value: '',
      placeholder: 'Postcode'
    },
    {
      key: 'city',
      value: '',
      placeholder: 'Plaats'
    }
  ],
  ProfilePriceFilterRanges: [
    {
      key: 'all',
      value: {
        min: null,
        max: null
      },
      placeholder: 'Alle'
    },
    {
      key: 'under150K',
      value: {
        min: null,
        max: '150000'
      },
      placeholder: 'Onder 150.000'
    },
    {
      key: '150K250K',
      value: {
        min: '150000',
        max: '250000'
      },
      placeholder: '150.000 tot 250.000'
    },
    {
      key: '250K350K',
      value: {
        min: '250000',
        max: '350000'
      },
      placeholder: '250.000 tot 350.000'
    },
    {
      key: '350K450K',
      value: {
        min: '350000',
        max: '450000'
      },
      placeholder: '350.000 tot 450.000'
    },
    {
      key: '450K550K',
      value: {
        min: '450000',
        max: '550000'
      },
      placeholder: '450.000 tot 550.000'
    },
    {
      key: '550K650K',
      value: {
        min: '550000',
        max: '650000'
      },
      placeholder: '550.000 tot 650.000'
    },
    {
      key: '650K750K',
      value: {
        min: '650000',
        max: '750000'
      },
      placeholder: '650.000 tot 750.000'
    },
    {
      key: '750K850K',
      value: {
        min: '750000',
        max: '850000'
      },
      placeholder: '750.000 tot 850.000'
    },
    {
      key: '850K1M',
      value: {
        min: '850000',
        max: '1000000'
      },
      placeholder: '850.000 tot 1.000.000'
    },
    {
      key: 'over1M',
      value: {
        min: '1000000',
        max: null
      },
      placeholder: 'Meer dan 1.000.000'
    },
  ]
};
