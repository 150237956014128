import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StoreInterface } from '@interfaces/store.interface';
import { Store } from '@ngrx/store';
import { ApiService } from '@services/api.service';
import * as OffersActions from '@actions/offers.actions';
import { exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class OffersEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OffersActions.offersGet),
      withLatestFrom(this.store.select('offers')),
      map(([action, offers]) => {
        return {
          page:  offers.currentPage,
          itemsPerPage: offers.itemsPerPage,
          searchFilters: offers.searchFilters
        };
      }),
      exhaustMap(({page, itemsPerPage, searchFilters}) => {
        return this.api.getOffers(page, itemsPerPage, null, searchFilters).pipe(
          map((offers) => {
            return OffersActions.offersSet({
              payload: {
                offers: offers.items,
                lastPage: Math.ceil(offers.totalItems / itemsPerPage),
                totalItems: offers.totalItems
              }
            });
          })
        );
      })
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OffersActions.offersUpdate),
      exhaustMap(() => {
        return of(OffersActions.offersGet());
      })
    )
  );

  constructor(
    private store: Store<StoreInterface>,
    private actions$: Actions,
    private api: ApiService
  ) {
  }
}
