import { Component, Inject, Input, OnInit } from '@angular/core';
import { Page } from '@pages/page';
import { Location } from '@angular/common';
import { ViewingRequest } from '@interfaces/viewing-request.interface';
import { Offer } from '@interfaces/offer.interface';
import { Reaction } from '@interfaces/reaction.interface';

@Component({
  selector: 'app-profile-properties',
  templateUrl: './profile-properties.component.html',
  styleUrls: ['./profile-properties.component.scss']
})
export class ProfilePropertiesComponent extends Page implements OnInit {
  @Input() title: string;
  @Input() profileList: (ViewingRequest | Offer | Reaction )[];
  @Input() totalItems: number;

  constructor(
    @Inject('CURRENCY_CODE') public currencyCode: string,
    public location: Location
  ) {
    super(location);
  }

  ngOnInit() {
  }
}
