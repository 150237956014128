import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfirmInterface } from '@interfaces/confirm.interface';
import { v4 as uuidv4 } from 'uuid';
import { Toast, ToastType } from '@interfaces/toast.interface';


@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts = new BehaviorSubject<Toast[]>([]);

  addToast(type: ToastType, text: string) {
    this.toasts.next([
      ...this.toasts.getValue(),
      {
        id: uuidv4(),
        type,
        text
      }
    ]);
  }

  removeToast(id: string) {
    this.toasts.next([
      ...this.toasts.getValue().filter(toast => toast.id !== id)
    ]);
  }
}
