import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '@services/api.service';
import { PropertyTypeActions } from '@actions/property-type.actions';
import { concatMap, map } from 'rxjs/operators';
import { StoreInterface } from '@interfaces/store.interface';

@Injectable()
export class PropertyTypeEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PropertyTypeActions.getPropertyTypes),
      concatMap(() => {
        return this.api.getPropertyTypes().pipe(
          map(types => {
            return PropertyTypeActions.getPropertyTypesFulfilled({
              payload: types
            });
          })
        );
      })
    )
  );

  constructor(
    private store: Store<StoreInterface>,
    private actions$: Actions,
    private api: ApiService
  ) { }
}
